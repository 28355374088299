// @codingStandardsIgnoreFile
'use strict'

const ApplicationDateCalendar = require('core/modules/applicationDateCalendar')

const bookingCancellationRefund = {
  currentDateValue: null,

  init: function () {
    const refundForm = document.querySelector('form[name="cancellation_refund"]')
    const dateField = document.getElementById('cancellation_refund_applicationDate')
    this.currentDateValue = dateField.value

    if (refundForm) {
      refundForm.querySelectorAll('refund_choice').forEach(item => {
        item.addEventListener('click', () => {
          item.getElementsByTagName('input')[0].checked = true
        })
      })
    }

    dateField.addEventListener('change', (e) => {
      let reload = false
      if (dateField.value !== this.currentDateValue) {
        reload = true
      }
      this.currentDateValue = dateField.value
      if (reload) {
        const loaderOverlay = document.querySelector('form .loader-overlay')
        loaderOverlay.classList.add('display-overlay')
        window.location.href = (window.location.href.split('?')[0]) + '?applicationDate=' + dateField.value
      }
    })

    this.initCalendar()
  },

  initCalendar: function () {
    this.calendar = new ApplicationDateCalendar(document.getElementById('cancellation_refund_applicationDate'))
  }
}

module.exports = bookingCancellationRefund.init()
